import { useState } from "react";
import Stand from "../_shared/Stand/Stand";
import FindStatistic from "./components/FindStatistic/FindStatistic";
import TablesStatistic from "./components/TablesStatistic/TablesStatistic";
import { usePWAList } from "../../hooks/usePWAList";

type productsType = { id: number; name: string }[];

const StatisticPage = () => {
  usePWAList();
  const [productTegs, setProductTegs] = useState<productsType>([]);
  const [productsPwa, setProductPwa] = useState<productsType>([]);

  return (
    <div>
      <Stand />
      <FindStatistic
        productTegs={productTegs}
        setProductTegs={setProductTegs}
        productsPwa={productsPwa}
        setProductPwa={setProductPwa}
      />
      <TablesStatistic
        productTegs={productTegs}
        productsPwa={productsPwa}
      />
    </div>
  );
};

export default StatisticPage;
