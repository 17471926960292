type Props = {
  variant?: 'right' | 'top' | 'bottom' | 'left';
};

export const VECTOR_SVG: React.FC<Props> = ({ variant = 'right' }) => {
  const variantTransform = () => {
    switch (variant) {
      case 'right':
        return {
          transform: 'rotate(0deg)',
          transformOrigin: 'center',
        };

      case 'top':
        return {
          transform: 'rotate(-90deg)',
          transformOrigin: 'center',
        };

      case 'bottom':
        return {
          transform: 'rotate(90deg)',
          transformOrigin: 'center',
        };

      case 'left':
        return {
          transform: 'rotate(180deg)',
          transformOrigin: 'center',
        };

      default:
        return {};
    }
  };

  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      xmlns="http://www.w3.org/2000/svg"
      style={variantTransform()}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="
          M0.528758 0.528606
          C0.789108 0.268256 1.21122 0.268256 1.47157 0.528606
          L5.47157 4.52861
          C5.73192 4.78896 5.73192 5.21107 5.47157 5.47141
          L1.47157 9.47141
          C1.21122 9.73176 0.789108 9.73176 0.528758 9.47141
          C0.268409 9.21107 0.268409 8.78896 0.528758 8.52861
          L4.05735 5.00001
          L0.528758 1.47141
          C0.268409 1.21107 0.268409 0.788955 0.528758 0.528606Z"
      />
    </svg>
  );
};

type PropsChevron_SVG = {
  isLeft?: boolean;
};

export const CHEVRON_SVG: React.FC<PropsChevron_SVG> = ({ isLeft = false }) => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(${isLeft ? 180 : 0}deg)` }}>
    <path d="M-6.16331e-08 10.5901L4.58 6.00012L-4.62904e-07 1.41012L1.41 0.000122009L7.41 6.00012L1.41 12.0001L-6.16331e-08 10.5901Z" fill="#868687"/>
  </svg>
);

export const STAR_SVG = () => (
  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.4 0L11.5104 6.49524H18.3399L12.8147 10.5095L14.9252 17.0048L9.4 12.9905L3.87482 17.0048L5.98525 10.5095L0.460069 6.49524H7.28957L9.4 0Z" fill="#323232"/>
  </svg>
);

type RedDeleteProps = {
  fill?: string;
};

export const RED_DELETE_SVG: React.FC<RedDeleteProps> = ({ fill = "#FF6B6C" }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.16667 0V0.833333H13.3333V2.5H12.5V13.3333C12.5 14.25 11.75 15 10.8333 15H2.5C1.58333 15 0.833333 14.25 0.833333 13.3333V2.5H0V0.833333H4.16667V0H9.16667ZM2.5 13.3333H10.8333V2.5H2.5V13.3333ZM4.16667 4.16667H5.83333V11.6667H4.16667V4.16667ZM9.16667 4.16667H7.5V11.6667H9.16667V4.16667Z" fill={fill}/>
  </svg>
);

export const ACCEPT_SVG = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="11" y="11" width="18" height="18" rx="2" fill="#4091F7"/>
    <path d="M18 24.4L14 20.4L15.4 19L18 21.6L24.6 15L26 16.4L18 24.4Z" fill="white"/>
  </svg>
);

export const NOTACCEPT_SVG = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="11" y="11" width="18" height="18" rx="2" stroke="#868687"/>
  </svg>
);

export const EDIT_SVG = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_674_1577)">
      <path d="M4.40004 14.1C4.50004 14.1 4.50004 14.1 4.60004 14.1L7.70004 13.7C8.00004 13.7 8.20004 13.5 8.40004 13.4L17 4.7C17.2 4.5 17.3 4.2 17.3 3.9C17.3 3.6 17.2 3.3 17 3.1L14.3 0.400002C13.8 -0.0999985 13.1 -0.0999985 12.6 0.400002L4.00004 9C3.80004 9.2 3.70004 9.4 3.70004 9.6L3.30004 12.7C3.20004 13.1 3.40004 13.4 3.60004 13.7C3.80004 14 4.10004 14.1 4.40004 14.1ZM5.10004 10L13.4 1.7L15.6 3.9L7.30004 12.2L4.70004 12.6L5.10004 10Z" fill="#4091F7"/>
      <path d="M18.4 16.4H0.8C0.4 16.4 0 16.7 0 17.2C0 17.7 0.3 18 0.8 18H18.4C18.8 18 19.2 17.7 19.2 17.2C19.2 16.7 18.8 16.4 18.4 16.4Z" fill="#4091F7"/>
    </g>

    <defs>
      <clipPath id="clip0_674_1577">
        <rect width="19.1" height="17.9" fill="white"/> 
      </clipPath>
    </defs>
  </svg>
);