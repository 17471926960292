import { StatisticType } from "../../../../../types/PushTupe";


type Nam = {
  id: number;
  name: keyof StatisticType;
  title: string;
  style: {
    width: string;
    paddingLeft?: string;
    paddingRight?: string;
  };
  styleItem: {
    [key: string]: any;
  };
  render?: (...args: any[]) => {};
};

export const listNames: Nam[] = [
  {
    id: 1,
    name: 'date',
    title: 'Date',
    style: {
      width: '130px',
      paddingLeft: '36px',
    },
    styleItem: {
      width: '130px',
      paddingLeft: '36px',
    },
  },
  {
    id: 2,
    name: 'deposit_percentage',
    title: 'Deposit Percentage',
    style: {
      width: '173px',
    },
    styleItem: {
      width: '173px',
    },
  },
  {
    id: 3,
    name: 'deposits',
    title: 'Deposits',
    style: {
      width: '100px',
    },
    styleItem: {
      width: '100px',
    },
  },
  {
    id: 4,
    name: 'first_open_percentage',
    title: 'First Open Percentage',
    style: {
      width: '190px',
    },
    styleItem: {
      width: '190px',
    },
  },
  {
    id: 5,
    name: 'first_opens',
    title: 'First Opens',
    style: {
      width: '120px',
    },
    styleItem: {
      width: '120px',
    },
  },
  {
    id: 6,
    name: 'open_percentage',
    title: 'Open Percentage',
    style: {
      width: '140px',
    },
    styleItem: {
      width: '140px',
    },
  },
  {
    id: 7,
    name: 'opens',
    title: 'Opens',
    style: {
      width: '80px',
    },
    styleItem: {
      width: '80px',
    },
  },
  {
    id: 8,
    name: 'push_open_percentage',
    title: 'Push Open Percentage',
    style: {
      width: '190px',
    },
    styleItem: {
      width: '190px',
    },
  },
  {
    id: 9,
    name: 'push_opened',
    title: 'Push Opened',
    style: {
      width: '120px',
    },
    styleItem: {
      width: '120px',
    },
  },
  {
    id: 10,
    name: 'push_received',
    title: 'Push Received',
    style: {
      width: '130px',
    },
    styleItem: {
      width: '130px',
    },
  },
  {
    id: 11,
    name: 'push_sent',
    title: 'Push Sent',
    style: {
      width: '110px',
    },
    styleItem: {
      width: '110px',
    },
  },
  {
    id: 12,
    name: 'push_subscriptions',
    title: 'Push Subscriptions',
    style: {
      width: '170px',
    },
    styleItem: {
      width: '170px',
    },
  },
  {
    id: 13,
    name: 'registration_percentage',
    title: 'Registration Percentage',
    style: {
      width: '190px',
    },
    styleItem: {
      width: '190px',
    },
  },
  {
    id: 14,
    name: 'registrations',
    title: 'Registrations',
    style: {
      width: '120px',
    },
    styleItem: {
      width: '120px',
    },
  },
  {
    id: 15,
    name: 'users',
    title: 'Users',
    style: {
      width: '80px',
    },
    styleItem: {
      width: '80px',
    }
  },
  {
    id: 16,
    name: 'visits',
    title: 'Visits',
    style: {
      width: '80px',
    },
    styleItem: {
      width: '80px',
    }
  }
];