import NavStatistic from "./NavStatistic/NavStatistic";
import { TablesPWAStyled } from "./styled";
import ListStatistic from "./ListStatistic/ListStatistic";
import { useEffect, useState } from "react";
import { getStatistic } from "../../../../api/requestService";
import { useAppDispatch } from "../../../../hooks/hooksStore";
import { setSatistics, setTotalPage } from "../../../../store/statistics/statistics";
import { toast } from "react-toastify";
import Pagination from "../../../../components/Pagination/Pagination";
import {StatisticType} from "../../../../types/PushTupe";

type productsType = { id: number; name: string }[];

type Props = {
  productTegs: productsType;
  productsPwa: productsType;
};

const TablesStatistic: React.FC<Props> = ({
  productTegs,
  productsPwa,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [perPage, setPerPage] = useState(8);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [productTegs, productsPwa]);

  useEffect(() => {
    const fetchStatistic = async () => {
        try {
            setLoading(true);
            const statistic = await getStatistic({
                "pwas": productsPwa.map(item => item.id),
                "tags": productTegs.map(item => item.id),
                "page": page,
                "per_page": perPage
            });

            const statisticFix = statistic.data.map((item: StatisticType) => (
                {
                    ...item,
                    deposit_percentage: Math.floor(item.deposit_percentage * 10) / 10,
                    first_open_percentage: Math.floor(item.first_open_percentage * 10) / 10,
                    open_percentage: Math.floor(item.open_percentage * 10) / 10,
                    push_open_percentage: Math.floor(item.push_open_percentage * 10) / 10,
                    registration_percentage: Math.floor(item.registration_percentage * 10) / 10,
                }
            ))

            dispatch(setSatistics(statisticFix));
            dispatch(setTotalPage(statistic.total_pages));
            setLoading(false);
        } catch {
            toast.error('Ошибка загрузки статистики!');
            setIsError(true);
        }
    };

      fetchStatistic();
  }, [page, productTegs, productsPwa, perPage]);

  const handleChangePage = (item: number) => {
    if (!loading) {
      setPage(item);
    }
  };

  return (
    <TablesPWAStyled>
      <NavStatistic perPage={perPage} setPerPage={setPerPage} />
      <ListStatistic isError={isError} isLoading={loading}/>
      <Pagination currentPage={page} onPageChange={handleChangePage}/>
    </TablesPWAStyled>
  );
};

export default TablesStatistic;
