import reload from '../../../../../images/reload.png';
import { ContentStyled, NavPWAStyled, ParagBigStyled, ParagStyled } from './styled';
import { ButtonStyled, ImgStyled } from '../../../../../helperStyled/styledHelper';
import SwitchCircle from '../../../../../components/SwitchCircle/SwitchCircle';
import { SelectInput } from '../../../../../components/Inputs/SelectInput/SelectInput';
import React from 'react';

type Props = {
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
}

const NavStatistic: React.FC<Props> = ({
  perPage,
  setPerPage,
}) => {
  const handleChangePerPage = (item: string) => {
    setPerPage(Number(item));
  }

  return (
    <NavPWAStyled>
      <ParagStyled>Statistic</ParagStyled>
      <SelectInput
        label='Количетсво элементов'
        items={['5', '7', '10']}
        placeholder='Выберите количество'
        onChange={handleChangePerPage}
        value={perPage.toString()}
      />
    </NavPWAStyled>
  )
}

export default NavStatistic;