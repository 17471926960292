import styled from "styled-components";

const FindStyled = styled.div`
  width: 100%;
  height: 206px;
  display: flex;
  align-items: center;
  padding-inline: 25px 29px;
  border: 1px solid #ececec;
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 20px;
`;

const FormStyled = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BlockIntutsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
`;

const ContainerInputStyled = styled.div`
  position: relative;
`;

const LabelStyled = styled.label`
  position: absolute;
  background-color: #fff;
  padding-inline: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  left: 12px;
  top: -8px;
`;

const InputStuled = styled.input`
  width: 240px;
  height: 56px;
  border: 1px solid #868687;
  border-radius: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding-left: 16px;
  transition: 0.3s;

  &:focus {
    padding-left: 15px;
    border: 3px solid #4091f7;
    outline: none;
  }
`;

const BlockButtonsStyled = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonContainerStyled = styled.div`
  margin-right: 8px;
`;

const ButtonSecondContainerStyled = styled.div`
  margin-right: 26px;
`;

const ButtonBotStyled = styled.div`
  background-color: #f5f5f5;
  border: none;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

export {
  FindStyled,
  FormStyled,
  BlockIntutsStyled,
  ContainerInputStyled,
  LabelStyled,
  InputStuled,
  BlockButtonsStyled,
  ButtonContainerStyled,
  ButtonSecondContainerStyled,
  ButtonBotStyled,
};
