import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store, { persistor } from "./store/store";
import Main from "./components/Main/Main";
import ListPage from "./pages/ListPage/ListPage";
import EditPage from "./pages/EditPage/EditPage";
import Auth from "./pages/AuthPage/auth";
import { PersistGate } from "redux-persist/integration/react";
import PushPage from "./pages/PushPage/PushPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import { App } from "./App";
import StatisticPage from "./pages/StatisticPage/StatisticPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route path="auth" element={<Auth />} />

              <Route path="main" element={<Main />}>
                <Route path="list" element={<ListPage />} />
                <Route path="create" element={<EditPage />} />
                <Route path="statistic" element={<StatisticPage />} />
                <Route path="edit" element={<EditPage />} />
                <Route path="push" element={<PushPage />} />
                <Route path="admin" element={<AdminPage />} />
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="main/list" replace />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
