import styled, { css } from 'styled-components';

const PaginationStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

const ListItemStyled = styled.li`
  flex-shrink: 0;
`;

const RightLeftStyled = styled.div`
  background-color: #4091F7;
  border-radius: 10px;
  padding: 7px 13px;
  cursor: pointer;
  user-select: none;

  svg {
    fill: white;
    width: 100%;
    height: 100%;
  }
`;

type NumType = {
  variant: 'active' | 'default';
}

const NumStyled = styled.div<NumType>`
  border-radius: 10px;
  padding: 7px 13px;
  cursor: pointer;
  user-select: none;

  ${({ variant }) => {
    switch (variant) {
      case 'active': return css`border: 1px solid #4091F7;`
      case 'default': return css`border: 1px solid grey;`
    }
  }}
`;

export { PaginationStyled, ListItemStyled, RightLeftStyled, NumStyled };