export const apiRoutes = {
  token: 'token',
  pwaList: 'pwa/',
  pwaItem: (id: string) => `pwa/${id}`,
  pwaCreate: 'pwa/create',
  pwaUpdate: (id: string) => `pwa/${id}/update`,
  pwaDelete: (id: string) => `pwa/${id}`,
  category_id: 'category/',
  trackers: 'tracker/',
  cloackSetting: 'cloack_settings/',
  cloackSettingCreate: 'cloack_settings/create',
  cloackSettingUpdate: (id: number) => `cloack_settings/${id}/update`,
  ratings: 'rating/',
  ratingCreate: 'rating/create',
  developers: 'developer/',
  developerCreate: 'developer/create',
  domains: 'domain/',
  review: `review/`,
  reviewItem: (id: number) => `review/${id}`,
  reviewsCreate: 'review/create',
  reviewsUpdate: (id: number) => `review/${id}/update`,
  reviewsDelete: (id: number) => `review/${id}/delete`,
  facebookPixels: 'pixel/facebook',
  tiktokPixels: 'pixel/tiktok',
  facebookPixelCreate: 'pixel/facebook/create',
  tiktokPixelCreate: 'pixel/tiktok/create',
  pushSets: 'push_set/',
  pushSetCreate: 'push_set/create',
  pushs: 'push/',
  pushDelete: (id: number) => `push/${id}`,
  pushCreate: 'push/create',
  pushItem: (id: number) => `push/${id}`,
  pushUpdate: (id: number) => `push/${id}/update`,
  namecheap: 'namecheap/',
  namecheapCreate: 'namecheap/create',
  cloudflare: 'cloudflare/',
  cloudflareCreate: 'cloudflare/create',
  domainCreate: 'domain/create',
  adminUser: 'admin_user/',
  adminUserUpdate: (id: number) => `admin_user/${id}/update`,
  adminUserCreate: 'admin_user/create',
  tags: 'tag/',
  tagCreate: 'tag/create',
  storeCategories: 'store_category/',
  statistic: 'statistics/',
};
