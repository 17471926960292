import { useState } from "react";
import { ButtonStyled } from "../../helperStyled/styledHelper";
import { ItemWaitingStyled, SpinerStyled, TextStyled } from "./styled";

interface Props {
  variant: `blue` | `white` | 'darkBlue' | 'darkBorder' | 'red';
  buttonText: string;
  css?: string;
  funcClick?: () => Promise<void>
};

const ButtonItem: React.FC<Props> = ({variant, css = '', funcClick = async () => {}, buttonText}) => {
  const [isWaiting, setIsWaiting] = useState(false);

  const handleClick = async () => {
    if (isWaiting) {
      return;
    };
    try {
      setIsWaiting(true);
      await funcClick();

    } catch (error) {
      throw error;
    } finally {
      setIsWaiting(false);
    }
  };

  return (
    <ButtonStyled variant={variant} css={css} onClick={handleClick} type='button'>
      <TextStyled>
        {buttonText}
        {isWaiting && (
          <ItemWaitingStyled variant={variant}>
            <SpinerStyled variant="white"/>
          </ItemWaitingStyled>
        )}
      </TextStyled>
    </ButtonStyled>
  )
};

export default ButtonItem;