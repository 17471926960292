import { useLocation } from "react-router-dom";
import { AdressStyled, BlockStyled, NameStyled, StandStyled } from "./styled";

const pathNames: { [key: string]: string } = {
  "/main/list": "Список",
  "/main/edit": "Редактирование",
  "/main/statistic": "Статистика",
  "/main/create": "Создание",
  "/main/push": "Push",
  "/main/admin": "Админка",
};

const Stand = () => {
  const path = useLocation();

  const pathName = pathNames[path.pathname] || "Not Found";

  return (
    <StandStyled>
      <BlockStyled>
        <AdressStyled>{`PWA / ${pathName}`}</AdressStyled>
        <NameStyled>{`${pathName}`}</NameStyled>
      </BlockStyled>
    </StandStyled>
  );
};

export default Stand;
