import { useEffect, useState } from "react";
import {
  BlockButtonsStyled,
  BlockIntutsStyled,
  ButtonBotStyled,
  ButtonContainerStyled,
  ButtonSecondContainerStyled,
  ContainerInputStyled,
  FindStyled,
  FormStyled,
  InputStuled,
  LabelStyled,
} from "./styled";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooksStore";
import { appListModel } from "../../../../models/appList";
import { setPWAArciveFilterList, setPWAFilterList } from "../../../../store/PWAList/PWAListSlice";
import { DatePickerInput } from "../../../../components/Inputs/DatePickerInput/DatePikerInput";
import { MultiSelectInput } from "../../../../components/Inputs/MultiSelectInput/MultiSelectInput";

type productsType = { id: number; name: string }[];

type Props = {
  productTegs: productsType;
  setProductTegs: React.Dispatch<React.SetStateAction<productsType>>
  productsPwa: productsType;
  setProductPwa: React.Dispatch<React.SetStateAction<productsType>>
};

const FindStatistic: React.FC<Props> = ({
  productTegs,
  setProductTegs,
  productsPwa,
  setProductPwa,
}) => {
  const { appsList } = useAppSelector((state) => state.pwalist);
  const appFormat = appsList.map((item, index) => {
    return { id: item.id, name: item.name, }
  });
  const { tagsAll = [] } = useAppSelector((state) => state.itemsSelect) || {};

  // useEffect(() => {
  //   if (appsList.length > 0) {
  //     const pwaItems = appsList.map((item) =>
  //       appListModel(item, category_idAll, domainsAll, tagsAll, pushSets));

  //     const pwaFilterItems = pwaItems
  //       .filter((item) => item.id.toString().includes(productId.toLowerCase()))
  //       .filter((item) =>
  //         !!item.create ? item.create.includes(productCreate || "") : true
  //       )
  //       .filter((item) => {
  //         if (productTegs.length === 0) {
  //           return true;
  //         }

  //         let res = false;

  //         for (const teg of productTegs) {
  //           if (item.teg.split(", ").includes(teg.name)) {
  //             res = true;
  //           }
  //         }

  //         return res;
  //       })
  //       .filter((item) =>
  //         (item.name ? item.name : '').toLowerCase().includes(productName.toLowerCase())
  //       );
  //     dispatch(setPWAFilterList(pwaFilterItems));
  //   }
  // }, [productTegs, productsPwa]);

  return (
    <FindStyled>
      <FormStyled>
        <BlockIntutsStyled>
          <ContainerInputStyled>
            <MultiSelectInput
              label="Теги"
              items={tagsAll}
              placehoder="Product Tags"
              value={productTegs}
              onChange={setProductTegs}
              variant="second"
              maxWidth="calc(100vw - 640.1px)"
            />
          </ContainerInputStyled>

          <ContainerInputStyled>
            <MultiSelectInput
              label="PWA"
              items={appFormat}
              placehoder="PWA"
              value={productsPwa}
              onChange={setProductPwa}
              variant="second"
              maxWidth="calc(100vw - 640.1px)"
            />
          </ContainerInputStyled>
        </BlockIntutsStyled>
      </FormStyled>
    </FindStyled>
  );
};

export default FindStatistic;
