import React, { useRef, useEffect, useState } from 'react';
import { ListRowItemStyled } from './styled';

type Props = {
  text: string | number,
  css: {[key: string]: any},
};

const ListRowItem: React.FC<Props> = ({ text, css }) => {
  return (
    <ListRowItemStyled css={css}>
       {text}
    </ListRowItemStyled>
  );
};

export default ListRowItem;