import { useAppSelector } from '../../hooks/hooksStore';
import { CHEVRON_SVG, VECTOR_SVG } from '../../utils/SVG';
import { ListItemStyled, NumStyled, PaginationStyled, RightLeftStyled } from './styled';

type Props = {
  currentPage: number;
  onPageChange: (value: number) => void;
};

const Pagination: React.FC<Props> = ({
  currentPage,
  onPageChange,
}) => {
  const { totalPage } = useAppSelector(state => state.statistics);

  if (totalPage === 0) {
    return <></>;
  }

  const handleChangePage = (num: number) => {
    onPageChange(num);
  };

  const pageCount = Array.from(
    { length: totalPage },
    (_, i) => i + 1,
  );

  const handleLeftClick = () => {
    if (currentPage !== 1) {
      handleChangePage(currentPage - 1);
    }
  };

  const handleRightClick = () => {
    if (currentPage < pageCount[pageCount.length - 1]) {
      handleChangePage(currentPage + 1);
    }
  };

  return (
    <PaginationStyled>
      <ListItemStyled>
        <RightLeftStyled onClick={handleLeftClick}>
          <VECTOR_SVG variant='left' />
        </RightLeftStyled>
      </ListItemStyled>

      {pageCount.length <= 5 ? (
        <>
          {pageCount.map(item => (
            <ListItemStyled key={item}>
              <NumStyled 
                variant={currentPage === item ? 'active' : 'default'}
                onClick={() => currentPage !== item && handleChangePage(item)}
              >
                {item}
              </NumStyled>
            </ListItemStyled>
          ))}
        </>
      ) : (
        <>
          <ListItemStyled>
            <NumStyled
              variant={currentPage === 1 ? 'active' : 'default'}
              onClick={() => currentPage !== 1 && handleChangePage(1)}
            >
              1
            </NumStyled>
          </ListItemStyled>

          {currentPage > 3 && <div style={{ alignSelf: 'end' }}>...</div>}

          {(() => {
            let startPage: number;
            let endPage: number;

            if (currentPage === 1 || currentPage === 2) {
              startPage = 2;
              endPage = 4;
            } else if (
              currentPage === pageCount[pageCount.length - 1] ||
              currentPage === pageCount[pageCount.length - 2]
            ) {
              startPage = pageCount[pageCount.length - 4];
              endPage = pageCount[pageCount.length - 2];
            } else {
              startPage = currentPage - 1;
              endPage = currentPage + 1;
            }

            return Array.from(
              { length: endPage - startPage + 1 },
              (_, i) => startPage + i,
            ).map(page => (
              <ListItemStyled key={page}>
                <NumStyled
                  variant={currentPage === page ? 'active' : 'default'}
                  onClick={() => currentPage !== page && handleChangePage(page)}
                >
                  {page}
                </NumStyled>
              </ListItemStyled>
            ));
          })()}

          {currentPage < pageCount[pageCount.length - 3] && (
            <div style={{ alignSelf: 'end' }}>...</div>
          )}

          <ListItemStyled>
            <NumStyled
              variant={currentPage === pageCount[pageCount.length - 1] ? 'active' : 'default'}
              onClick={() =>
              currentPage !== pageCount[pageCount.length - 1] &&
              handleChangePage(pageCount[pageCount.length - 1])
            }>
              {pageCount[pageCount.length - 1]}
            </NumStyled>
          </ListItemStyled>
        </>
      )}

      <ListItemStyled>
        <RightLeftStyled onClick={handleRightClick}>
          <VECTOR_SVG variant='right' />
        </RightLeftStyled>
      </ListItemStyled>
    </PaginationStyled>
  );
};

export default Pagination;