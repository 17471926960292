import styled from 'styled-components';

const NavPWAStyled = styled.div`
  width: 100%;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 35px 28.07px;
`;

const ParagStyled = styled.p`
  font-weight: 500;
`;

const ParagBigStyled = styled.p`
  font-weight: 400;
`;

const ContentStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
`;



export {
  NavPWAStyled,
  ParagStyled,
  ParagBigStyled,
  ContentStyled,
}