import {
  ContainerStyled,
  ListNameItemStyled,
  ListNamesStyled,
  ListRowContainerStyled,
  ListRowStyled,
  ListStyled,
  LoadingStyled,
} from "./styled";
import { listNames } from "./ListNamesAPI";
import React from "react";
import ListRowItem from "./ListRowFixWidth/ListRowFixWidth";
import { SpinerStyled } from "../../../../../components/ButtonItem/styled";
import { useAppSelector } from "../../../../../hooks/hooksStore";

type Props = {
  isLoading: boolean;
  isError: boolean;
};

const ListStatistic: React.FC<Props> = ({ isLoading, isError }) => {
  const { statistics } = useAppSelector(state => state.statistics);

  return (
    <ContainerStyled>
      <ListStyled>
        <ListNamesStyled>
          {listNames.map((nam) => (
            <ListNameItemStyled css={nam.style} key={nam.id}>
              {nam.title}
            </ListNameItemStyled>
          ))}
        </ListNamesStyled>

        <ListRowContainerStyled>
          {isLoading && (
            <LoadingStyled>
              <SpinerStyled variant="blue"/>
            </LoadingStyled>
          )}

          {isError && !isLoading && !['items'].length && <div>Error Download Items</div>}

          {!!statistics.length && !isLoading &&
            statistics.map((pwa) => (
              <ListRowStyled key={pwa + '.id'}>
                {listNames.map((nam) => (
                  <>
                    <ListRowItem
                      text={pwa[nam.name]?.toString() || ''}
                      css={nam.styleItem}
                    />
                  </>
                ))}
              </ListRowStyled>
            ))}
        </ListRowContainerStyled>
      </ListStyled>
    </ContainerStyled>
  );
};

export default ListStatistic;
